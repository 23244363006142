// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { MyLure } from './MyLure';
import type { MyLureForm } from './MyLureForm';

export default class MyLureClient extends AxiosClient {
  fetchAll(account: Account): AxiosPromise<Array<MyLure>> {
    return this.buildTokenAuthClient(account).get(`/my/tackles/lures.json`);
  }

  fetchAllRaws(account: Account): AxiosPromise<Array<MyLure>> {
    return this.buildTokenAuthClient(account).get(
      `/my/tackles/lures/raws.json`
    );
  }

  fetchMyLure(id: number, account: Account): AxiosPromise<MyLure> {
    return this.buildTokenAuthClient(account).get(
      `/my/tackles/lures/${id}.json`
    );
  }

  create(
    form: MyLureForm,
    account: Account,
    token: string
  ): AxiosPromise<Array<MyLure>> {
    return this.buildMultipartTokenAuthClient(account, token).post(
      `/my/tackles/lures.json`,
      this._buildFormData(form)
    );
  }

  update(
    id: number,
    form: MyLureForm,
    account: Account,
    token: string
  ): AxiosPromise<Array<MyLure>> {
    return this.buildMultipartTokenAuthClient(account, token).patch(
      `/my/tackles/lures/${id}.json`,
      this._buildFormData(form)
    );
  }

  delete(id: number, account: Account): AxiosPromise<any> {
    return this.buildTokenAuthClient(account).delete(
      `/my/tackles/lures/${id}.json`
    );
  }

  _buildFormData(form: MyLureForm) {
    const data = new FormData();
    const { maker, color, name, memo, get_date, lost_date } = form;

    data.append('mytackle_lure[maker_id]', maker ? maker.id.toString() : '');
    data.append('mytackle_lure[colornm]', color || '');
    data.append('mytackle_lure[lurenm]', name || '');
    data.append('mytackle_lure[memo]', memo || '');
    data.append(
      'mytackle_lure[getdate]',
      get_date ? get_date.format('YYYYMMDD') : ''
    );
    data.append(
      'mytackle_lure[lostdate]',
      lost_date ? lost_date.format('YYYYMMDD') : ''
    );

    form.images.forEach((image, i) => {
      data.append(
        `mytackle_lure[images_attributes][${i}][_destroy]`,
        image._destroy.toString()
      );

      if (image.id != null) {
        data.append(
          `mytackle_lure[images_attributes][${i}][id]`,
          image.id.toString()
        );
      }
      if (image.image != null) {
        data.append(
          `mytackle_lure[images_attributes][${i}][image]`,
          image.image
        );
      }
    });

    return data;
  }
}
