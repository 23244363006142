// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import UserClient from '../../../models/users/UserClient';
import Loader from '../../commons/Loader';
import { displayMessage } from '../../commons/messages';
import { connect } from 'react-redux';
import type { User } from '../../../models/users/User';
import type { Account } from '../../../models/accounts/Account';
import { csrfToken } from '../../../helpers/fetch';

type Props = {
  account: Account,
};

type State = {
  users: Array<User>,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
};

class BlocksView extends React.Component<Props, State> {
  userClient = new UserClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      isLoading: true,
      page: 1,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    this.fetchBlocks();
  }

  fetchBlocks() {
    const { page } = this.state;

    this.userClient
      .fetchBlocks(page, csrfToken())
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.link &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          users: [...this.state.users, ...response.data].filter(
            (u, i, self) => self.map(u2 => u2.id).indexOf(u.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.warn(error);
      });
  }

  onLoadMore = () => {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchBlocks()
      );
    }
  };

  onClickRemove(userId: number) {
    if (window.confirm('ブロックを解除しますか？')) {
      this.userClient
        .stopBlocking(userId, csrfToken())
        .then(() => {
          this.setState({
            users: this.state.users.filter(u => u.id !== userId),
          });
          displayMessage('ブロックを解除しました');
        })
        .catch(error => {
          console.warn(error);
        });
    }
  }

  render() {
    const { users } = this.state;

    return (
      <div className="listview bordered">
        <InfiniteScroll
          pageStart={0}
          loadMore={this.onLoadMore}
          hasMore={this.state.hasNextPage}
          loader={<Loader key={0} />}
        >
          {users.map(user => (
            <div key={user.id} className="listview-item">
              <div className="listview-content media">
                {user.mini_url != null && (
                  <a href={`/users/${user.id}`}>
                    <img
                      src={user.mini_url}
                      width={48}
                      className="rounded-circle mr-3"
                    />
                  </a>
                )}
                <div className="media-body">
                  <a href={`/users/${user.id}`}>
                    <h6 className="mt-0">{user.name}</h6>
                  </a>
                  <div className="text-muted profile_comment">{user.profile_comment}</div>
                </div>
              </div>
              <div className="listview-actions">
                <i
                  onClick={() => this.onClickRemove(user.id)}
                  className="listview-actions-item ion-ios-close-outline"
                />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = state => state;
export default connect(mapStateToProps)(BlocksView);
