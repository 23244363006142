// @flow
import moment from 'moment';
import VenueClient from '../../models/venues/VenueClient';

export default class TideGraph {
  venueId: number;
  selector: string;
  options: any;
  venueClient = new VenueClient();

  constructor(venueId: number, selector: string) {
    this.venueId = venueId;
    this.selector = selector;
  }

  show(datetime: moment) {
    this.fetchVenue(this.venueId, datetime);
  }

  fetchVenue(venueId: number, datetime: moment) {
    this.venueClient
      .fetchVenueWithConditions(venueId, datetime)
      .then(response => {
        this.render(datetime, response.data.perdays[0]);
      })
      .catch(error => {
        console.error(error);
      });
  }

  setOptions(options) {
    this.options = options;
  }

  /**
   * 以下の仕様で潮位を決定する
   * ・同時（HH）内に満干潮があり、釣果分（mm）＜満干潮分（mm）であれば、その時間（HH）にDot。
   * ・同時（HH）内に満干潮があり、釣果分（mm）＞満干潮分（mm）であれば、＋１時間（HH）にDot。
   * ・その他、30分〜翌時29分は最も近い時（HH）にDot。
   * @see https://github.com/anglers/Anglers/issues/89
   */
  buildTideHour(datetime: moment, tidePerDay: any) {
    let tideTimes = [
      tidePerDay.hightide1time,
      tidePerDay.hightide2time,
      tidePerDay.hightide3time,
      tidePerDay.hightide4time,
      tidePerDay.lowtide1time,
      tidePerDay.lowtide2time,
      tidePerDay.lowtide3time,
      tidePerDay.lowtide4time,
    ];

    // 日付(HHmm)として変換出来るものだけ抽出
    tideTimes = tideTimes.filter(t => !isNaN(parseInt(t)));

    // 同時内が存在する配列のindex取得する
    let posSameHour = -1;
    const momFisTime = moment(datetime, 'HHmm');
    for (let i = 0; i < tideTimes.length; i++) {
      if (moment(tideTimes[i], 'HHmm').isSame(momFisTime, 'hour')) {
        posSameHour = i;
        break;
      }
    }

    if (posSameHour < 0) {
      // 最も近い時に合わす（HH）
      return momFisTime.startOf('minutes').format('HH');
    }

    if (momFisTime.isAfter(moment(tideTimes[posSameHour], 'HHmm'))) {
      // 釣果時刻 > 満干潮時刻であれば、分を切り上げ
      return momFisTime.endOf('minutes').format('HH');
    } else {
      // 釣果時刻 <= 満干潮時刻であれば、分を切り捨て
      return momFisTime.startOf('minutes').format('HH');
    }
  }

  render(datetime: moment, tidePerDay: any) {
    const options = {};
    const labels = tidePerDay.perhours.map(d => d.observehour);
    const data = tidePerDay.perhours.map(d => d.tide);
    // 0を当日とし、当日分のみdotをタイドグラフに打つ
    //const nowH = datetime.hours();
    const nowH = this.buildTideHour(datetime, tidePerDay);
    //const nowM = datetime.minutes();
    const nowM = 0;
    const appDotIndex = parseInt(nowH) + parseFloat(nowM / 60);
    let pointBackgroundColors = [];
    let pointBorderColors = [];
    let pointRadius = [];

    for (let i = 0, len = 25; i < len; i++) {
      if (i == appDotIndex) {
        pointBackgroundColors.push('#f39c12');
        pointBorderColors.push('#e67e22');
        pointRadius.push(5);
      } else {
        pointBackgroundColors.push('#fff');
        pointBorderColors.push('#fff');
        pointRadius.push(0);
      }
    }

    const currentTide = data[appDotIndex]; // 現在の潮位を取得

    const lineChartData = {
      labels: labels,
      datasets: [
        {
          label: '潮位',
          backgroundColor: 'rgba(51,181,229,.2)',
          borderColor: '#33B5E5',
          pointBackgroundColor: pointBackgroundColors,
          pointBorderColor: pointBorderColors,
          pointRadius: pointRadius,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: data,
          lineTension: 0.4, // グラフの滑らかさのオプション
        },
      ],
    };

    const defaults = {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          position: 'top',
          text: `潮位: ${currentTide}` // タイトルに現在の潮位を追加
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: '#fff',
            },
            gridLines: {
              color: '#34495e',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: '#fff',
            },
            gridLines: {
              color: '#34495e',
            },
          },
        ],
      },
      tooltips: {
        enabled: false,
      },
      responsive: false,
      animation: {
        animateRotate: false,
      },
      animationSteps: 20,
      datasetFill: true,
      ...this.options,
    };

    for (let key in defaults) {
      if (typeof options[key] !== 'undefined') {
        defaults[key] = options[key];
      }
    }

    new Chart(
      $(this.selector)
        .get(0)
        .getContext('2d'),
      {
        type: 'line',
        data: lineChartData,
        options: defaults,
      }
    );
  }
}
