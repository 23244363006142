// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { MyRod } from './MyRod';
import type { MyRodForm } from './MyRodForm';

export default class MyRodClient extends AxiosClient {
  fetchAll(account: Account): AxiosPromise<Array<MyRod>> {
    return this.buildTokenAuthClient(account).get(`/my/tackles/rods.json`);
  }

  fetchMyRod(id: number, account: Account): AxiosPromise<MyRod> {
    return this.buildTokenAuthClient(account).get(
      `/my/tackles/rods/${id}.json`
    );
  }

  create(
    form: MyRodForm,
    account: Account,
    token: string
  ): AxiosPromise<MyRod> {
    return this.buildMultipartTokenAuthClient(account, token).post(
      `/my/tackles/rods.json`,
      this._buildFormData(form)
    );
  }

  update(
    id: number,
    form: MyRodForm,
    account: Account,
    token: string
  ): AxiosPromise<MyRod> {
    return this.buildMultipartTokenAuthClient(account, token).patch(
      `/my/tackles/rods/${id}.json`,
      this._buildFormData(form)
    );
  }

  delete(id: number, account: Account): AxiosPromise<any> {
    return this.buildTokenAuthClient(account).delete(
      `/my/tackles/rods/${id}.json`
    );
  }

  _buildFormData(form: MyRodForm) {
    const data = new FormData();
    const { maker, series, product, memo, get_date, lost_date } = form;

    data.append('mytackle_rod[maker_id]', maker ? maker.id.toString() : '');
    data.append('mytackle_rod[series_id]', series ? series.id.toString() : '');
    data.append(
      'mytackle_rod[product_id]',
      product ? product.id.toString() : ''
    );
    data.append('mytackle_rod[memo]', memo || '');
    data.append(
      'mytackle_rod[getdate]',
      get_date ? get_date.format('YYYYMMDD') : ''
    );
    data.append(
      'mytackle_rod[lostdate]',
      lost_date ? lost_date.format('YYYYMMDD') : ''
    );

    form.images.forEach((image, i) => {
      if (image.id == null || image._destroy) {
        data.append(
          `mytackle_rod[images_attributes][${i}][_destroy]`,
          image._destroy.toString()
        );

        if (image.id != null) {
          data.append(
            `mytackle_rod[images_attributes][${i}][id]`,
            image.id.toString()
          );
        }
        if (image.image != null) {
          data.append(
            `mytackle_rod[images_attributes][${i}][image]`,
            image.image
          );
        }
      }
    });

    return data;
  }
}
