// @flow
import React from 'react';
import GoogleMap from 'google-map-react';
import AreaMarker from './AreaMarker';
import InfiniteScroll from 'react-infinite-scroller';
import Header from '../layouts/Header';
import LocationSearchInput from './LocationSearchInput';
import Loader from './Loader';
import { IndexBase } from './IndexBase';
import type { Props as BaseProps, State as BaseState } from './IndexBase';
import FishOk from '../../assets/images/views/areas/fish_ok.svg';
import FishNg from '../../assets/images/views/areas/fish_ng.svg';

type Props = BaseProps & {
  logo: string,
  fdl: ?string,
};

type State = BaseState & {};

export default class IndexView extends IndexBase<Props, State> {
  scrollHeight: number;
  googleMap: GoogleMap;
  markerClicked = false;

  onMouseInteraction = (area: ?Area) => {
    if (!this.markerClicked) {
      this.setState({ activeArea: area });
    }
  };

  onLoadMore = () => {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchAreas()
      );
    }
  };

  onSearch = (lat: number, lng: number) => {
    this.onChangeAddress(lat, lng);
    this.googleMap.map_.panTo({ lat: lat, lng: lng });
  };

  handleMapClick = () => {
    this.markerClicked = false;
    this.setState({ activeArea: null });
  };

  constructor(props: Props) {
    super(props);

    this.scrollHeight = window.innerHeight - 50;

    this.state = {
      areas: [],
      activeArea: null,
      position: this.defaultPosition,
      isLoading: true,
      page: 1,
      hasNextPage: true,
      address: '',
    };
  }

  render() {
    const { apiKey } = this.props;
    const { areas, activeArea, hasNextPage } = this.state;

    return (
      <div>
        <Header
          mobile={false}
          logo={this.props.logo}
          logoIcon=""
          active="areas"
          userId={this.props.userId}
          userName={this.props.userName}
          fdl={this.props.fdl}
        >
          <LocationSearchInput
            address={this.state.address}
            onSearch={this.onSearch}
            onSearchClose={() => console.warn('search closed')}
          />
        </Header>
        <div
          style={{
            height: this.scrollHeight,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '32%',
              height: this.scrollHeight,
            }}
          >
            <div
              className="listview lv-bordered lv-lg"
              style={{ height: this.scrollHeight, overflow: 'auto' }}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={this.onLoadMore}
                hasMore={hasNextPage}
                loader={<Loader />}
                className="lv-body"
                useWindow={false}
              >
                {areas.map(area => (
                  <a
                    href={`/areas/${area.id}`}
                    key={area.id}
                    className="lv-item"
                    onMouseEnter={() => this.setState({ activeArea: area })}
                    onMouseLeave={() => this.setState({ activeArea: null })}
                  >
                    <div className="media row">
                      <div className="text-center col-3">
                        <img
                          src={
                            area.is_banned && area.is_banned ? FishNg : FishOk
                          }
                          width={64}
                          height={64}
                        />
                      </div>
                      <div className="media-body col-9 row">
                        <h4 className="col-12" style={{ marginTop: '3px' }}>
                          {area.name}
                        </h4>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-5">
                              {!area.is_banned &&
                                area.summed_children_results_count != null && (
                                  <div className="text-muted">
                                    <i className="icon-app f-17 mr-5" />
                                    {area.summed_children_results_count}
                                  </div>
                                )}
                            </div>
                            <div className="col-7 text-right">
                              {!area.is_banned &&
                                area.latest_caught_at != null && (
                                  <div className="text-muted">
                                    <i className="fa fa-clock-o fa-fw mr-5" />
                                    {area.latest_caught_at.fromNow(true)}前
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {area.is_banned && (
                            <div>
                              <span className="fish-forbidden-label">
                                <span
                                  className="fish-forbidden-text"
                                  style={{ fontSize: '11px' }}
                                >
                                  釣り禁止
                                </span>
                              </span>
                            </div>
                          )}
                          {!area.is_banned && area.children_names != null && (
                            <div className="tags">
                              {area.children_names.map(child_name => (
                                <span
                                  className="badge badge-light"
                                  style={{ marginRight: '1px' }}
                                  key={child_name}
                                >
                                  {child_name}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: '68%',
              height: '100%',
            }}
          >
            <GoogleMap
              ref={googleMap => (this.googleMap = googleMap)}
              bootstrapURLKeys={{ key: apiKey }}
              defaultCenter={[
                this.defaultPosition.coords.latitude,
                this.defaultPosition.coords.longitude,
              ]}
              defaultZoom={11}
              options={this.mapOptions}
              onChange={this.onChangeMap}
              onClick={this.handleMapClick}
            >
              {areas.map(area => (
                <AreaMarker
                  key={area.id}
                  lat={area.lat}
                  lng={area.lng}
                  area={area}
                  active={activeArea != null && activeArea.id === area.id}
                  onMouseEnter={() => this.onMouseInteraction(area)}
                  onMouseLeave={() => this.onMouseInteraction(null)}
                  onClick={() => {
                    this.markerClicked = true;
                    this.setState({ activeArea: area });
                  }}
                />
              ))}
            </GoogleMap>
          </div>
        </div>
      </div>
    );
  }
}
